<template>
  <Fragment>
    <Wrapper class="page-gps">
      <Titlebar>
        <h5 slot="title" class="title is-5 is-marginless">GPS</h5>

        <div slot="local">
          <div class="field">
            <div class="control">
              <button class="button is-primary" @click="activateGps">
                {{ $t('gps.activateGps') }}
              </button>
            </div>
          </div>
        </div>
      </Titlebar>
    </Wrapper>

    <div v-if="hasDevices">
      <GpsDeviceCard
        v-for="gps in devices"
        :key="gps.id"
        :gps="gps">
      </GpsDeviceCard>
    </div>

    <div v-else class="default-spacing is-paddingless-top">
      <p>{{ $t('gps.activateGpsHelpText') }}</p>
    </div>
  </Fragment>
</template>

<script>
import GpsDeviceCard from './GpsDeviceCard.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    GpsDeviceCard
  },

  computed: {
    ...mapGetters({
      devices: 'subscription/devices'
    }),

    hasDevices () {
      return this.devices && this.devices.length > 0
    }
  },

  methods: {
    async activateGps () {
      this.$store.dispatch('ui/newGpsModal', true)
    }
  }
}
</script>
