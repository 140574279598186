<template>
  <RouterLink :to="`/gps/${gps.imei}/coverage`" class="box">
    <div class="level">
      <div class="level-left">
        <div>
          <h4 v-if="gps.imei" class="title is-5">
            GPS: {{ gps.imei }}
          </h4>
        </div>
      </div>
    </div>
  </RouterLink>
</template>

<script>
import * as _ from '@/assets/libs/utils/'

export default {

  props: {
    gps: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    capitalize (str) {
      return _.capitalize(str)
    }
  }

}
</script>

<style lang="scss" scoped>
.box {
  padding: 0.75rem 1.5rem !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
  border-radius: 0;
  border-bottom: 1px solid #f2f2f2;

  &:first-of-type {
    border-top: 1px solid #f2f2f2;
  }

  &:hover {
    background: #f7f7f7;
  }

  .level-left {
    width: calc(100% - 4rem);

    > div {
      width: 100%;
    }

    .title {
      line-height: 1.5;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
